var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "div",
        { staticClass: "main-content" },
        [
          _c("div", { staticClass: "top-button" }),
          _c(
            "el-tabs",
            {
              staticClass: "custom-tabs",
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  label: _vm.$t("lang_client_toggle_form"),
                  name: "form",
                },
              }),
              _c("el-tab-pane", {
                attrs: {
                  label: _vm.$t("lang_client_toggle_flowchart"),
                  name: "flow",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currTabName == "form",
                  expression: "currTabName == 'form'",
                },
              ],
              staticStyle: { height: "calc(100% - 58px)" },
              attrs: { gutter: 10 },
            },
            [
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 24 } },
                [
                  _c("div", { staticClass: "main-card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "deal-content",
                        style: {
                          color: _vm.fontColor ? _vm.fontColor : "black",
                        },
                      },
                      [
                        _c("form-viewer", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currTabName == "form",
                              expression: "currTabName == 'form'",
                            },
                          ],
                          ref: "formViewerRef",
                          staticClass: "deal-form",
                          attrs: {
                            flowId: _vm._flowConfig.id,
                            btnObj: _vm.currentLayoutBtn,
                            operation: _vm.operation,
                            readonly: _vm.readonly,
                          },
                          on: { submit: _vm.handleOperation },
                        }),
                        _c(
                          "el-footer",
                          { staticClass: "deal-btns" },
                          [
                            _vm._l(_vm.curOptBtns, function (item) {
                              return _c(
                                "el-button",
                                {
                                  key: item.actionKey,
                                  staticClass: "deal-btn-item",
                                  attrs: {
                                    type: _vm.getButtonType(item.operation),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleOperation(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.displayActionName))]
                              )
                            }),
                            _vm.showPrint
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "deal-btn-item",
                                    attrs: { type: "primary" },
                                    on: { click: _vm.printFlow },
                                  },
                                  [_vm._v("打印")]
                                )
                              : _vm._e(),
                            _vm.showProcess
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "deal-btn-item",
                                    attrs: { type: "primary" },
                                    on: { click: _vm.ccFlow },
                                  },
                                  [_vm._v("抄送")]
                                )
                              : _vm._e(),
                            _vm.showReject
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "deal-btn-item",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rejectFlow()
                                      },
                                    },
                                  },
                                  [_vm._v("退回")]
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currTabName == "flow",
                  expression: "currTabName == 'flow'",
                },
              ],
              staticStyle: { height: "calc(100% - 58px)" },
              attrs: { gutter: 10 },
            },
            [
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 19 } },
                [
                  _c("div", { staticClass: "main-card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "deal-content",
                        style: {
                          color: _vm.fontColor ? _vm.fontColor : "black",
                        },
                      },
                      [
                        _c("flow-graph", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currTabName == "flow",
                              expression: "currTabName == 'flow'",
                            },
                          ],
                          ref: "flowGrapgRef",
                          staticClass: "deal-flow",
                          attrs: { taskDataId: _vm.taskDataId, readonly: true },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { height: "100%" },
                  attrs: { offset: 0, span: 5 },
                },
                [
                  _c("div", { staticClass: "main-card" }, [
                    _c(
                      "div",
                      { staticClass: "deal-logs" },
                      [_c("flow-logs", { attrs: { data: _vm._logs } })],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_countersign"),
            visible: _vm.assignDialogFlag,
            "custom-class": "cc-dialog",
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.assignDialogFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "cc-form",
              staticStyle: { height: "300px" },
              attrs: { model: _vm.assignModel, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_sign_signer") } },
                [
                  _c("user-transfer", {
                    attrs: {
                      data: _vm.assignModel.identityScope,
                      multiple: true,
                    },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(
                          _vm.assignModel,
                          "identityScope",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_sign_add_comments") } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.assignModel.submitWords,
                      callback: function ($$v) {
                        _vm.$set(_vm.assignModel, "submitWords", $$v)
                      },
                      expression: "assignModel.submitWords",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.assignDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAssign } },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_cc"),
            visible: _vm.ccDialogFlag,
            "custom-class": "cc-dialog",
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.ccDialogFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "cc-form",
              staticStyle: { height: "300px" },
              attrs: { model: _vm.ccModel, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_CC_person") } },
                [
                  _c("user-transfer", {
                    attrs: { data: _vm.ccModel.identityScope, multiple: true },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(_vm.ccModel, "identityScope", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_cc_comments") } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.ccModel.ccWords,
                      callback: function ($$v) {
                        _vm.$set(_vm.ccModel, "ccWords", $$v)
                      },
                      expression: "ccModel.ccWords",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.ccDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleCc } },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_turn_to_do"),
            visible: _vm.dgDialogFlag,
            "custom-class": "cc-dialog",
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dgDialogFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "cc-form",
              staticStyle: { height: "300px" },
              attrs: { model: _vm.dgModel, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_transfer_person") } },
                [
                  _c("user-transfer", {
                    attrs: { data: _vm.dgModel.identityScope, multiple: true },
                    on: {
                      "update:data": function ($event) {
                        return _vm.$set(_vm.dgModel, "identityScope", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("lang_transfer_opinion") } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.dgModel.delegateWords,
                      callback: function ($$v) {
                        _vm.$set(_vm.dgModel, "delegateWords", $$v)
                      },
                      expression: "dgModel.delegateWords",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dgDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleDelegate },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.anySubmitDialogFlag
              ? "提交至选择节点"
              : _vm.$t("lang_free_return"),
            visible: _vm.freeReturnDialogFlag,
            "custom-class": "cc-dialog",
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.freeReturnDialogFlag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "cc-form",
              staticStyle: { height: "300px" },
              attrs: { model: _vm.assignModel, "label-width": "120px" },
            },
            [
              _vm.anySubmitDialogFlag
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择节点" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择将要提交到的节点",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.freeReturnNodeId,
                            callback: function ($$v) {
                              _vm.freeReturnNodeId = $$v
                            },
                            expression: "freeReturnNodeId",
                          },
                        },
                        _vm._l(_vm.getHistoryNodes(), function (item) {
                          return _c("el-option", {
                            key: item.nodeId,
                            attrs: {
                              label: item.displayNodeName,
                              value: item.nodeId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_return_node") } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t(
                              "lang_please_select_a_return_node"
                            ),
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.freeReturnNodeId,
                            callback: function ($$v) {
                              _vm.freeReturnNodeId = $$v
                            },
                            expression: "freeReturnNodeId",
                          },
                        },
                        _vm._l(_vm.getHistoryNodes(), function (item) {
                          return _c("el-option", {
                            key: item.nodeId,
                            attrs: {
                              label: item.displayNodeName,
                              value: item.nodeId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.freeReturnDialogFlag = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleReject(null)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._flowConfig.id
        ? _c("report-list", {
            attrs: {
              visible: _vm.printDialogFlag,
              dataId: _vm._currentTask.runtimeId,
              linkId: _vm._flowConfig.id,
            },
            on: {
              "update:visible": function ($event) {
                _vm.printDialogFlag = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "正在处理子账号待办任务",
            visible: _vm.changeUser1,
            "append-to-body": "",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changeUser1 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "change-user-con" },
            [
              _c("el-card", [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "18px", "font-weight": "550" },
                  },
                  [_vm._v("请选任务处理人身份：")]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "change-user-body",
                  staticStyle: { "margin-top": "20px" },
                },
                _vm._l(_vm.userTaskPrincipal, function (item) {
                  return _c(
                    "el-card",
                    {
                      key: item.userId,
                      staticClass: "box-card",
                      class:
                        item.userId == _vm.getEntrustUsersId
                          ? "box-card-alive"
                          : "",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.clicktEntrustUsersId(item.userId)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: item.userId },
                              model: {
                                value: _vm.getEntrustUsersId,
                                callback: function ($$v) {
                                  _vm.getEntrustUsersId = $$v
                                },
                                expression: "getEntrustUsersId",
                              },
                            },
                            [_vm._v("选择用户")]
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "box-header" }, [
                        _c("div", { staticClass: "box-header-left" }, [
                          item.avatar
                            ? _c("img", {
                                attrs: {
                                  src:
                                    "/river-fs/file/view?fileId=" +
                                    item.avatar +
                                    "&token=" +
                                    _vm.token,
                                  alt: "",
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: require("@/assets/image/user_unknow.png"),
                                  alt: "",
                                },
                              }),
                        ]),
                        _c("div", { staticClass: "box-header-right" }, [
                          _c("div", { staticClass: "box-list-item" }, [
                            _c("span", [_vm._v(_vm._s(item.userName))]),
                          ]),
                          _c("div", { staticClass: "box-list-item" }, [
                            _c("span", [_vm._v(_vm._s(item.account))]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "box-bottom" }, [
                        _c("div", { staticClass: "box-list-item" }, [
                          _c("span", [_vm._v(_vm._s(item.companyName))]),
                        ]),
                        _c("div", { staticClass: "box-list-item" }, [
                          _c("span", [_vm._v(_vm._s(item.deptName))]),
                        ]),
                        _c("div", { staticClass: "box-list-item" }, [
                          _c("span", [_vm._v(_vm._s(item.dutyName))]),
                        ]),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.clickSelectUserId },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.changeUser1 = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "正在处理委托待办任务",
            visible: _vm.changeUser2,
            "append-to-body": "",
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.changeUser2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "change-user-con" },
            [
              _c(
                "div",
                { staticClass: "change-user-header" },
                [
                  _c(
                    "el-card",
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "18px",
                            "font-weight": "550",
                          },
                        },
                        [_vm._v("请选任务处理人身份：")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "login" },
                          model: {
                            value: _vm.submitAsLoginOrPrincipal,
                            callback: function ($$v) {
                              _vm.submitAsLoginOrPrincipal = $$v
                            },
                            expression: "submitAsLoginOrPrincipal",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_submit_as_login")))]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "principal" },
                          model: {
                            value: _vm.submitAsLoginOrPrincipal,
                            callback: function ($$v) {
                              _vm.submitAsLoginOrPrincipal = $$v
                            },
                            expression: "submitAsLoginOrPrincipal",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_submitted_as_principal")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.submitAsLoginOrPrincipal == "principal"
                ? [
                    _c(
                      "div",
                      { staticClass: "change-user-body" },
                      _vm._l(_vm.userDetailPrincipal, function (item) {
                        return _c(
                          "el-card",
                          {
                            key: item.userId,
                            staticClass: "box-card",
                            class:
                              item.userId == _vm.principalUserId
                                ? "box-card-alive"
                                : "",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.clickPrincipalUserId(item.userId)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: item.userId },
                                    model: {
                                      value: _vm.principalUserId,
                                      callback: function ($$v) {
                                        _vm.principalUserId = $$v
                                      },
                                      expression: "principalUserId",
                                    },
                                  },
                                  [_vm._v("选择用户")]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "box-header" }, [
                              _c("div", { staticClass: "box-header-left" }, [
                                item.avatar
                                  ? _c("img", {
                                      attrs: {
                                        src:
                                          "/river-fs/file/view?fileId=" +
                                          item.avatar +
                                          "&token=" +
                                          _vm.token,
                                        alt: "",
                                      },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/image/user_unknow.png"),
                                        alt: "",
                                      },
                                    }),
                              ]),
                              _c("div", { staticClass: "box-header-right" }, [
                                _c("div", { staticClass: "box-list-item" }, [
                                  _c("span", [_vm._v(_vm._s(item.userName))]),
                                ]),
                                _c("div", { staticClass: "box-list-item" }, [
                                  _c("span", [_vm._v(_vm._s(item.account))]),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "box-bottom" }, [
                              _c("div", { staticClass: "box-list-item" }, [
                                _c("span", [_vm._v(_vm._s(item.companyName))]),
                              ]),
                              _c("div", { staticClass: "box-list-item" }, [
                                _c("span", [_vm._v(_vm._s(item.deptName))]),
                              ]),
                              _c("div", { staticClass: "box-list-item" }, [
                                _c("span", [_vm._v(_vm._s(item.dutyName))]),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.clickSubmitAsLoginOrPrincipal },
                },
                [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.changeUser2 = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }