













































































































































































































































































import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import FormViewer from '@/components/form-design/viewer/form-viewer.vue';
import FlowGraph from '@/components/flow-design/flow-graph.vue';
import FlowLogs from '@/views/home/flow2/flow-logs.vue';
import UserTransfer from '@/components/user-transfer/index.vue';
import ReportList from '@/components/report-design/report-list.vue';
import { NodeConfig } from '@logicflow/core';
import { GraphNodeData } from '@/models/flow/GraphNodeModel';
import ReportApi from '@/api/report-design/ReportApi';
import { assignTask, backTask, ccTask, executorList, delegateTask, getTaskDetail, jumpTask2, submitTask } from '@/api/flow-design/FlowApi';
import { deleteManage } from '@/api/data-manage/TaskApi';
import { entrustUsers } from '@/api/delegation/EntrustApi';
import { getSimpleTakeOffices } from '@/api/system/UserApi';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';

@Component({
  name: 'UserHomeFlowViewer',
  components: { FormViewer, FlowGraph, FlowLogs, UserTransfer, ReportList }
})
export default class UserHomeFlowViewer extends Vue {
  @Prop({ default: '' }) operation!: string; // 操作类型  process_new task_startup task_runtime task_completed task_end
  @Prop({ default: '' }) taskDataId!: string; // 任务id
  @Prop({ default: true }) readonly!: boolean; // 任务id
  @Prop({ default: false }) isUndo!: boolean; // 任务id

  @ProvideReactive() flowId: any = null;
  @ProvideReactive() formId: any = null;
  @ProvideReactive() formDataId: any = null;

  token: string = '';

  activeName: string = 'form'; // tab按钮名称
  showGoHome: boolean = false;
  urgentFlag: boolean = false;
  taskName: string = '';
  currTabName: string = 'form'; // 当前显示内容（form: 表单； flow: 流程图）
  currentGraphNode: NodeConfig = GraphNodeData;

  currentFormData: any = {};

  currentOperationBtn: any = {}; // 当前点击的按钮(流程事件类型-提交、退回等)
  currentLayoutBtn: any = {}; // 当前点击的按钮（页面切换类型的-弹出框、切换页面）
  curOptBtns: any[] = []; // 页面切换后要的按钮
  stepBtnsStack: any[] = []; // 将每一步的按钮放入堆栈中，方便切换

  // 加签
  assignDialogFlag: boolean = false;
  assignModel: any = {
    identityScope: {
      userIds: [],
      groupIds: []
    },
    submitWords: ''
  };

  // 抄送
  ccDialogFlag: boolean = false;
  ccModel: any = {
    identityScope: {
      userIds: [],
      groupIds: []
    },
    ccWords: ''
  };

  // 转办
  dgDialogFlag: boolean = false;
  dgModel: any = {
    identityScope: {
      userIds: [],
      groupIds: []
    },
    ccWords: ''
  };

  printDialogFlag: boolean = false; // 是否显示 选择打印模板 的弹出框

  borderColor: string = 'transparent'; // 边框颜色
  fontColor: string = ''; // 字体颜色

  freeReturnNodeId: string = ''; // 自由退回节点id
  anySubmitNodeId: string = ''; //提交至任意节点id
  anySubmitDialogFlag: boolean = false; //是否显示 提交至任意节点 的弹出框
  freeReturnDialogFlag: boolean = false; // 是否显示 选择退回节点 的弹出框;

  submitAsLoginOrPrincipal: string = 'principal'; //提交人身份 用户自选--principal、当前登陆人--login
  changeUser1: boolean = false;
  changeUser2: boolean = false;
  userTaskPrincipal: any = [];
  userDetailPrincipal: any = [];
  user1Alive: number = 1;
  principalUserId: string = ''; //用户自选时的 身份人id
  getEntrustUsersId: string = ''; //用于请求第二个接口的 身份人id
  submitFlowformSaveData: any = null;
  taskId: string = '';
  showPrint: boolean = false; //是否显示打印按钮
  showProcess: boolean = false; //是否显示抄送按钮
  showReject: boolean = false; //是否显示退回按钮
  // 打印配置列表
  reportList: any[] = [];
  get _flowConfig() {
    return this.$store.getters.flowConfig;
  }

  get _description() {
    if (this._flowConfig && this._flowConfig.description) {
      this._flowConfig.displayDescription;
    }
    return '';
  }

  //当前节点
  get _activedNode() {
    return this.$store.getters.activedNode;
  }

  //当前节点
  get _currentTask() {
    return this.$store.getters.currentTask;
  }

  get _processInstance() {
    return this.$store.getters.processInstance;
  }

  // 流程标题
  get _taskName() {
    if (this._flowConfig && this._flowConfig.id && this._activedNode && this._activedNode.id) {
      return this._processInstance.title + '-' + (this._flowConfig.displayName || this.$t(this._flowConfig.name));
    }
    return '';
  }

  // 流程日志
  get _logs() {
    return this.$store.getters.processLogs;
  }

  // 流程日志
  get _taskHistories() {
    return this.$store.getters.taskHistories;
  }

  get _formConfigs() {
    return this.$store.getters.formConfigs;
  }

  get _pageLoading() {
    if (this._taskName && this.formId) {
      return true;
    }
    return false;
  }

  get _user() {
    return this.$store.getters.user;
  }
  // 流程删除数据
  get _processRuntime() {
    return this.$store.getters.processRuntime;
  }

  created() {
    this.token = StorageUtil.getSessionStorage('token');
    if (this.$route.query.seeType === 'flow') {
      if (this.$route.query.backFlg) {
        this.showGoHome = true;
      }
      this.currTabName = 'flow';
      this.activeName = 'flow';
    } else {
      this.currTabName = 'form';
      this.activeName = 'form';
    }
    let formConfig = this._formConfigs[this.formId];
    if (formConfig && formConfig.container.properties) {
      if (formConfig.container.properties.hasBorder) {
        this.borderColor = formConfig.container.properties.borderColor;
      }
      this.fontColor = formConfig.container.properties.fontColor;
    } else {
      this.borderColor = 'transparent';
    }

    // 获取流程日志
    // this.getFlowLog();
  }

  /**
   * 初始化页面数据
   */
  initData() {
    // 已结束的流程不展示撤回 、删除按钮
    if (this._processInstance.end) {
      this.curOptBtns = this.filterBtns(this._activedNode.properties.nodeActions, ['revoke', 'delete']);
    } else {
      this.curOptBtns = this.filterBtns(this._activedNode.properties.nodeActions);
    }
    // 打印按钮
    if (this.curOptBtns && this.curOptBtns.length > 0) {
      let printData = this.curOptBtns.find(item => item.operation == 'print');
      if (printData) {
        this.showPrint = false;
      } else if (this.reportList && this.reportList.length > 0 && !printData) {
        this.showPrint = true;
      }
    } else if (this.reportList && this.reportList.length > 0) {
      this.showPrint = true;
    }

    // 抄送按钮
    if (this.curOptBtns && this.curOptBtns.length > 0) {
      let processData = this.curOptBtns.find(item => item.operation == 'cc');
      this.showProcess = processData ? false : true;
    } else {
      this.showProcess = true;
    }

    // 代办加退回按钮
    if (this.isUndo) {
      if (this.curOptBtns && this.curOptBtns.length > 0) {
        let backData = this.curOptBtns.find(item => item.operation == 'disagree');
        this.showReject = backData ? false : true;
      } else {
        this.showReject = true;
      }
    }
    this.stepBtnsStack.push(this.curOptBtns);
  }

  filterBtns(btns, notNeedShowButton?) {
    let result: any[] = [];
    btns.forEach(btnItem => {
      if (!notNeedShowButton && btnItem.taskPages.includes(this.operation)) {
        if (btnItem.children && btnItem.children.length) {
          btnItem.children = this.filterBtns(btnItem.children, notNeedShowButton);
        }
        result.push(btnItem);
      } else if (notNeedShowButton && btnItem.taskPages.includes(this.operation) && !notNeedShowButton.includes(btnItem.operation)) {
        if (btnItem.children && btnItem.children.length) {
          btnItem.children = this.filterBtns(btnItem.children, notNeedShowButton);
        }
        result.push(btnItem);
      }
    });
    return result;
  }

  // 按钮颜色
  getButtonType(type) {
    const buttonColor: any = {
      delete: 'danger'
    };
    if (buttonColor[type]) return buttonColor[type];
    return 'primary';
  }

  /**
   * 构造所有按钮的map
   */
  buildOptBtnMap(btns) {
    let result = {};
    btns.forEach(btnItem => {
      result[btnItem.actionKey] = btnItem;
      if (btnItem.children && btnItem.children.length) {
        result = Object.assign(result, this.buildOptBtnMap(btnItem.children));
      }
    });
    return result;
  }

  // 切换显示内容
  changeTab(tabName) {
    this.currTabName = tabName;
    if (tabName == 'flow') {
      (this.$refs.flowGrapgRef as any).focusOnCenter();
    }
  }
  handleClick() {
    this.changeTab(this.activeName);
  }

  /**
   * 点击按钮时
   */
  handleOperation(btnItem) {
    if (btnItem.operation) {
      this.currentOperationBtn = { ...btnItem };
      switch (btnItem.operation) {
        case 'submit':
          this.submitFlow();
          break;
        case 'submitAny':
          this.rejectFlow('submitAny');
          break;
        case 'disagree':
          this.rejectFlow();
          break;
        case 'revoke':
          this.revokeFlow();
          break;
        case 'assign':
          this.assignFlow();
          break;
        case 'delegate':
          this.delegateFlow();
          break;
        case 'cc':
          this.ccFlow();
          break;
        case 'print':
          this.printFlow();
          break;
        case 'delete':
          this.removeFlow();
          break;
        default:
          this.saveFlow();
      }
    } else {
      this.currentLayoutBtn = { ...btnItem };
      this.curOptBtns = this.getOptBtns(btnItem);
    }
  }

  /**
   * 获取页面切换后的按钮
   */
  getOptBtns(btnItem) {
    if (btnItem.actionType == 'form_flush') {
      this.stepBtnsStack.push(btnItem.children);
    } else if (btnItem.actionType == 'form_back') {
      this.stepBtnsStack.pop();
    } else if (btnItem.actionType == 'form_dialog') {
    }
    if (this.stepBtnsStack && this.stepBtnsStack.length) {
      return this.stepBtnsStack[this.stepBtnsStack.length - 1];
    } else {
      return [];
    }
  }

  submitOrRejectInitFn() {
    this.submitFlowformSaveData = null;
    this.principalUserId = '';
  }

  removeFlow() {
    this.$confirm(this.$t('lang_whether_or_not_to_delete') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    })
      .then(() => {
        if (this._processRuntime.id && this._processRuntime.processId) {
          deleteManage({ processId: this._processRuntime.processId, runtimeId: this._processRuntime.id, ...{ checkStatus: true } })
            .then((res: Record<any, any>) => {
              if (res.code == '1') {
                // 提示成功
                this.$message.success(res.message);
                // postMessage 前一个页面中-流程组件：刷新列表
                let message = { data: 'success', key: 'viewerSubmitSuccess' };
                window.opener.postMessage(JSON.stringify(message), '/');
                this.closeWindow_old();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch(e => {
              this.$message.error(e);
            });
        }
      })
      .catch(() => {});
  }

  //提交逻辑
  // 提交
  async submitFlow() {
    // 获取表单参数
    this.submitOrRejectInitFn();
    this.submitFlowformSaveData = await this.getFormDataForSave();
    // 正常的提交逻辑
    this.submitMainLogicFn();
  }
  //新增的提交主要逻辑--更具依次调用的两个接口返回的数据 执行不同的逻辑
  async submitMainLogicFn() {
    // 接口一
    let executorList: any = await this.getExecutorList();
    // 接口一异常提示
    if (executorList.code != '1') {
      this.$message.error(executorList.message);
      return;
    }
    // 接口一正常逻辑
    if (executorList.code == '1' && executorList.data) {
      // 如果 接口一 只返回一条数据 默认调用第二个接口
      if (executorList.data.length == 1) {
        // 第二个接口
        let entrustUsers: any = await this.getEntrustUsers(executorList.data[0]);
        // 如果第二个接口有数据 调用第三个接口
        if (entrustUsers.data) {
          let userDetailPrincipal: any = await this.getSimpleTakeOfficesFn(entrustUsers.data);
          this.userDetailPrincipal = userDetailPrincipal.data;
          if (this.userDetailPrincipal.length == 1) {
            this.principalUserId = this.userDetailPrincipal[0].userId;
          }
          this.changeUser2 = true;
        } else {
          // 直接触发提交
          // 最终触发提交逻辑
          // await this.$confirm(this.$t('lang_submit_task') as string, this.$t('lang_tips') as string, {
          //   confirmButtonText: this.$t('lang_determine_') as string,
          //   cancelButtonText: this.$t('lang_cancel_') as string,
          //   type: 'warning'
          // });
          this.submitTaskFn(this.submitFlowformSaveData, executorList.data[0]);
        }
      }
      // 如果 接口一 返回多条数据 显示弹框 用户选择后继续
      if (executorList.data.length > 1) {
        // 调用第三个接口
        let userTaskPrincipal: any = await this.getSimpleTakeOfficesFn(executorList.data);
        this.userTaskPrincipal = userTaskPrincipal.data;
        this.changeUser1 = true;
        //
      }
    } else {
      // 接口二
      let entrustUsers: any = await this.getEntrustUsers(this._user.id);
      // 如果第二个接口有数据 调用第三个接口
      if (entrustUsers.data && entrustUsers.code == '1') {
        let userDetailPrincipal: any = await this.getSimpleTakeOfficesFn(entrustUsers.data);
        this.userDetailPrincipal = userDetailPrincipal.data;
        if (this.userDetailPrincipal.length == 1) {
          this.principalUserId = this.userDetailPrincipal[0].userId;
        }
        this.changeUser2 = true;
      } else {
        // 直接触发提交
        // 最终触发提交逻辑
        // let confirm1 = await this.$confirm(this.$t('lang_submit_task') as string, this.$t('lang_tips') as string, {
        //   confirmButtonText: this.$t('lang_determine_') as string,
        //   cancelButtonText: this.$t('lang_cancel_') as string,
        //   type: 'warning'
        // });
        this.submitTaskFn(this.submitFlowformSaveData, this._user.id);
      }
    }
  }

  //原始提交逻辑
  submitTaskFn(formSaveData, submitUser?) {
    let submitUsers = '';
    if (this.submitAsLoginOrPrincipal == 'login') {
      submitUsers = this.taskId;
    } else {
      submitUsers = submitUser;
    }
    let params: any = {
      id: this._currentTask.id,
      formDataInstance: {
        formId: this._flowConfig.customBpmModel.config.formId,
        id: this._currentTask.dataInstanceId,
        formModelDataList: formSaveData.formModelDataList
      },
      arguments: this.buildBtnParams(this.currentOperationBtn),
      submitUser: submitUsers
    };

    this.buildGraphParams(params);
    submitTask(params).then((res: any) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        // postMessage 前一个页面中-流程组件：刷新列表
        let message = { data: 'success', key: 'viewerSubmitSuccess' };
        window.opener.postMessage(JSON.stringify(message), '/');
        this.closeWindow_old();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  currentTaskId: string = '';
  //提交时 第一个接口
  getExecutorList() {
    return new Promise((resolve, reject) => {
      let params = {
        id: this.taskDataId
      };
      executorList(params)
        .then(res => {
          if (res.data && res.data.length == 1) {
            this.taskId = res.data[0];
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
          this.$message.error(err.message);
        });
    });
  }
  //提交时 第二个接口
  getEntrustUsers(userId: string) {
    return new Promise((resolve, reject) => {
      let params = {
        userId: userId,
        processId: this._flowConfig.id,
        taskInstanceId: this.currentTaskId
      };
      entrustUsers(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
          this.$message.error(err.message);
        });
    });
  }
  // 第三个接口
  getSimpleTakeOfficesFn(userIds) {
    return new Promise((resolve, reject) => {
      let params = {
        userIds
      };
      getSimpleTakeOffices(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
          this.$message.error(err.message);
        });
    });
  }

  // 第一个弹框的确定
  async clickSelectUserId() {
    // 第二个接口
    let entrustUsers: any = await this.getEntrustUsers(this.getEntrustUsersId);
    // 如果第二个接口有数据 调用第三个接口
    if (entrustUsers.data) {
      let userDetailPrincipal: any = await this.getSimpleTakeOfficesFn(entrustUsers.data);
      this.userDetailPrincipal = userDetailPrincipal.data;
      if (this.userDetailPrincipal.length == 1) {
        this.principalUserId = this.userDetailPrincipal[0].userId;
      }
      this.changeUser2 = true;
    } else {
      // 直接触发提交
      // 最终触发提交逻辑
      this.submitTaskFn(this.submitFlowformSaveData, this._user.id);
    }
  }
  // 第二个弹框的确定
  clickSubmitAsLoginOrPrincipal() {
    // 以登录人身份提交
    if (this.submitAsLoginOrPrincipal == 'login') this.submitTaskFn(this.submitFlowformSaveData, this._user.id);
    // 以委托人身份提交
    else this.submitTaskFn(this.submitFlowformSaveData, this.principalUserId);
  }

  // 退回
  async rejectFlow(submitAny?: string) {
    // 获取表单参数
    this.submitOrRejectInitFn();
    this.submitFlowformSaveData = await this.getFormDataForSave();
    let freeReturn = false;
    this.anySubmitDialogFlag = false; //默认不显示 提交至任意节点，默认显示退回至任意节点
    if (this._activedNode.properties && this._activedNode.properties.returnSetting) {
      freeReturn = this._activedNode.properties.returnSetting.freeReturn;
      if (freeReturn || submitAny == 'submitAny') {
        if (submitAny == 'submitAny') this.anySubmitDialogFlag = true; //显示 提交至任意节点
        this.currentFormData = this.submitFlowformSaveData;
        this.freeReturnNodeId = '';
        this.freeReturnDialogFlag = true;
      } else {
        // 接口一
        let executorList: any = await this.getExecutorList();
        if (executorList.data) {
          // 如果 接口一 只返回一条数据 默认调用第二个接口
          if (executorList.data.length == 1) {
            // 第二个接口
            let entrustUsers: any = await this.getEntrustUsers(executorList.data[0]);
            // 如果第二个接口有数据 调用第三个接口
            if (entrustUsers.data) {
              let userDetailPrincipal: any = await this.getSimpleTakeOfficesFn(entrustUsers.data);
              this.userDetailPrincipal = userDetailPrincipal.data;
              if (this.userDetailPrincipal.length == 1) {
                this.principalUserId = this.userDetailPrincipal[0].userId;
              }
              this.changeUser2 = true;
            } else {
              // 直接触发提交
              // 最终触发提交逻辑
              // 最终触发提交逻辑
              await this.handleRejectFn(this._user.id);
            }
          }
          // 如果 接口一 返回多条数据 显示弹框 用户选择后继续
          if (executorList.data.length > 1) {
            // 调用第三个接口
            let userTaskPrincipal: any = await this.getSimpleTakeOfficesFn(executorList.data);
            this.userTaskPrincipal = userTaskPrincipal.data;
            this.changeUser1 = true;
            //
          }
        } else {
          // 接口二
          let entrustUsers: any = await this.getEntrustUsers(this._user.id);
          // 如果第二个接口有数据 调用第三个接口
          if (entrustUsers.data) {
            let userDetailPrincipal: any = await this.getSimpleTakeOfficesFn(entrustUsers.data);
            this.userDetailPrincipal = userDetailPrincipal.data;
            if (this.userDetailPrincipal.length == 1) {
              this.principalUserId = this.userDetailPrincipal[0].userId;
            }
            this.changeUser2 = true;
          } else {
            // 直接触发提交
            // 最终触发提交逻辑
            await this.handleRejectFn(this._user.id);
          }
        }
      }
    }
  }

  async handleRejectFn(submitUser) {
    // await this.$confirm(this.$t('lang_return_task') as string, this.$t('lang_tips') as string, {
    //   confirmButtonText: this.$t('lang_determine_') as string,
    //   cancelButtonText: this.$t('lang_cancel_') as string,
    //   type: 'warning'
    // });

    let targetNodeId = '';
    if (this._activedNode.properties && this._activedNode.properties.returnSetting) {
      targetNodeId = this._activedNode.properties.returnSetting.returnNodeId;
    }
    if (targetNodeId) {
      this.handleReject(targetNodeId, submitUser);
    } else {
      this.$message.error(this.$t('lang_config_select_a_return_node') as any);
    }
  }

  // 退回，需要表单校验
  handleReject(targetNodeId, submitUser?) {
    // 获取表单参数
    if (!targetNodeId) {
      if (!this.freeReturnNodeId) {
        this.$message.error(this.$t('lang_please_select_node') as any);
        return;
      }
      targetNodeId = this.freeReturnNodeId;
    }
    let params: any = {
      id: this._currentTask.id,
      targetNodeId: targetNodeId,
      // anySubmitDialogFlag==true 时 表示自由提交（跳转）
      submitType: this.anySubmitDialogFlag ? 4 : 5,
      formDataInstance: {
        formId: this._flowConfig.customBpmModel.config.formId,
        id: this._currentTask.dataInstanceId,
        formModelDataList: this.currentFormData.formModelDataList
      },
      arguments: this.buildBtnParams(this.currentOperationBtn),
      submitUser
    };
    this.buildGraphParams(params);
    backTask(params).then((res: any) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        // postMessage 前一个页面中-流程组件：刷新列表
        let message = { data: 'success', key: 'viewerSubmitSuccess' };
        window.opener.postMessage(JSON.stringify(message), '/');
        this.closeWindow_old();
      } else {
        this.$message.error(res.message);
      }
    });
  }

  // 撤回，不需要表单校验
  revokeFlow() {
    // 获取表单参数
    this.$confirm(this.$t('lang_withdraw_task') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params: any = {
        processId: this._flowConfig.id,
        runtimeId: this._currentTask.runtimeId,
        targetNodeId: this._activedNode.id,
        submitType: 6,
        checkStatus: true,
        arguments: this.buildBtnParams(this.currentOperationBtn)
      };
      jumpTask2(params).then((res: any) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // postMessage 前一个页面中-流程组件：刷新列表
          let message = { data: 'success', key: 'viewerSubmitSuccess' };
          window.opener.postMessage(JSON.stringify(message), '/');
          this.closeWindow_old();
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 打开加签窗口
  assignFlow() {
    this.getFormDataForSave().then(res => {
      this.currentFormData = res;
      this.assignModel = {
        identityScope: {
          userIds: [],
          groupIds: []
        },
        submitWords: ''
      };
      this.assignDialogFlag = true;
    });
  }

  // 提交加签，需要表单校验
  handleAssign() {
    if (
      (!this.assignModel.identityScope.userIds || !this.assignModel.identityScope.userIds.length) &&
      (!this.assignModel.identityScope.groupIds || !this.assignModel.identityScope.groupIds.length)
    ) {
      this.$message.error(this.$t('lang_select_assigner') as any);
      return;
    }
    this.$confirm(this.$t('lang_assign_task') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params: any = {
        ...this.assignModel,
        id: this._currentTask.id,
        formDataInstance: {
          formId: this._flowConfig.customBpmModel.config.formId,
          id: this._currentTask.dataInstanceId,
          formModelDataList: this.currentFormData.formModelDataList
        },
        arguments: this.buildBtnParams(this.currentOperationBtn)
      };
      assignTask(params).then((res: any) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          this.assignDialogFlag = false;
          // postMessage 前一个页面中-流程组件：刷新列表
          let message = { data: 'success', key: 'viewerSubmitSuccess' };
          window.opener.postMessage(JSON.stringify(message), '/');
          this.closeWindow_old();
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 打开抄送窗口
  ccFlow() {
    this.ccModel = {
      identityScope: {
        userIds: [],
        groupIds: []
      },
      ccWords: ''
    };
    this.ccDialogFlag = true;
  }

  // 提交抄送，不需要表单校验
  handleCc() {
    if (
      (!this.ccModel.identityScope.userIds || !this.ccModel.identityScope.userIds.length) &&
      (!this.ccModel.identityScope.groupIds || !this.ccModel.identityScope.groupIds.length)
    ) {
      this.$message.error(this.$t('lang_select_cc') as any);
      return;
    }
    this.$confirm(this.$t('lang_cc_task') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params: any = {
        nodeInstanceId: this.taskDataId,
        ...this.ccModel,
        arguments: this.buildBtnParams(this.currentOperationBtn)
      };
      ccTask(params).then((res: any) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          this.ccDialogFlag = false;
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 打开转办窗口
  delegateFlow() {
    this.dgModel = {
      identityScope: {
        userIds: [],
        groupIds: []
      },
      delegateWords: ''
    };
    this.dgDialogFlag = true;
  }

  // 提交转办，不需要表单校验
  handleDelegate() {
    if (
      (!this.dgModel.identityScope.userIds || !this.dgModel.identityScope.userIds.length) &&
      (!this.dgModel.identityScope.groupIds || !this.dgModel.identityScope.groupIds.length)
    ) {
      return;
    }
    this.$confirm(this.$t('lang_transfer_task') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params: any = {
        nodeInstanceId: this.taskDataId,
        ...this.dgModel,
        arguments: this.buildBtnParams(this.currentOperationBtn)
      };
      delegateTask(params).then((res: any) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // postMessage 前一个页面中-流程组件：刷新列表
          let message = { data: 'success', key: 'viewerSubmitSuccess' };
          window.opener.postMessage(JSON.stringify(message), '/');
          this.closeWindow_old();
          this.dgDialogFlag = false;
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  // 暂存，不需要表单校验
  saveFlow() {
    this.getFormDataForSave(false).then(res => {
      this.$confirm(this.$t('lang_draft') as string, this.$t('lang_tips') as string, {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }).then(() => {
        let params: any = {
          startWait: true,
          id: this._currentTask.id,
          formDataInstance: {
            formId: this._flowConfig.customBpmModel.config.formId,
            id: this._currentTask.dataInstanceId,
            formModelDataList: res.formModelDataList
          },
          arguments: this.buildBtnParams(this.currentOperationBtn)
        };
        this.buildGraphParams(params);
        submitTask(params).then((res: any) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            // this.closeWindow();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    });
  }

  getHistoryNodes() {
    const obj: { [key: string]: boolean } = {};
    let arr = [];
    arr = this._taskHistories.filter(item => {
      return ['start', 'wait'].includes(item.taskClassName);
    });
    return arr.reduce((item, next) => {
      if (!obj[next.nodeId]) {
        item.push(next);
        obj[next.nodeId] = true;
      }
      return item;
    }, []);
  }

  // 获取表单数据
  getFormDataForSave(validate: boolean = true) {
    return (this.$refs.formViewerRef as any).getFormDataForSave(validate);
  }

  // 判断流程图是否有变化，有的话 将最新的流程图配置传给后台
  buildGraphParams(params) {
    let newGraphData = (this.$refs.flowGrapgRef as any).getGraphData();
    let oldGraphData = this._flowConfig.customBpmModel.graph;
    // 处理翻译
    if (oldGraphData.nodes) {
      oldGraphData.nodes.forEach(item => {
        if (item.text.displayValue) {
          delete item.text.displayValue;
        }
      });
    }
    if (!this.isEqual(newGraphData, oldGraphData)) {
      let runtimeCustomModel = {
        config: this._flowConfig.customBpmModel.config,
        graph: newGraphData
      };
      params.runtimeCustomModel = runtimeCustomModel;
    }
  }

  //判断两个对象是否相等
  isEqual(objA, objB) {
    //相等
    if (objA === objB) return objA !== 0 || 1 / objA === 1 / objB;
    //空判断
    if (objA == null || objB == null) return objA === objB;
    //类型判断
    if (Object.prototype.toString.call(objA) !== Object.prototype.toString.call(objB)) return false;

    switch (Object.prototype.toString.call(objA)) {
      case '[object RegExp]':
      case '[object String]':
        //字符串转换比较
        return '' + objA === '' + objB;
      case '[object Number]':
        //数字转换比较,判断是否为NaN
        if (+objA !== +objA) {
          return +objB !== +objB;
        }

        return +objA === 0 ? 1 / +objA === 1 / objB : +objA === +objB;
      case '[object Date]':
      case '[object Boolean]':
        return +objA === +objB;
      case '[object Array]':
        //判断数组
        for (let i = 0; i < objA.length; i++) {
          if (!this.isEqual(objA[i], objB[i])) return false;
        }
        return true;
      case '[object Object]':
        //判断对象
        let keys = Object.keys(objA);
        for (let i = 0; i < keys.length; i++) {
          if (!this.isEqual(objA[keys[i]], objB[keys[i]])) return false;
        }

        keys = Object.keys(objB);
        for (let i = 0; i < keys.length; i++) {
          if (!this.isEqual(objA[keys[i]], objB[keys[i]])) return false;
        }

        return true;
      default:
        return false;
    }
  }

  // 构建按钮参数
  buildBtnParams(btnItem) {
    let result = {};
    if (btnItem.paramValueSettings) {
      btnItem.paramValueSettings.forEach(item => {
        result[item.paramOptionName] = item.value;
      });
    }
    return result;
  }

  /**
   * 打印
   */
  printFlow() {
    this.printDialogFlag = true;
  }

  /**
   * 获取打印模板
   */
  getReportList() {
    if (this._flowConfig.id) {
      let params = { linkId: this._flowConfig.id };
      ReportApi.getReportList(params).then((res: any) => {
        if (res.code == '1' && res.data) {
          this.reportList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }

  closeWindow() {
    let windowObj: any = window;
    if (windowObj.EventBridge) {
      windowObj.EventBridge.close();
    } else {
      this.goToHome();
    }
  }

  // 关闭页面
  closeWindow_old() {
    if (navigator.userAgent.indexOf('MSIE') > 0) {
      // IE
      if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
        // IE6
        window.opener = null;
        window.close();
      } else {
        // IE6+
        window.open('', '_top');
        window.top!.close();
      }
    } else if (navigator.userAgent.indexOf('Firefox') > 0 || navigator.userAgent.indexOf('Presto') > 0) {
      // FF和Opera
      window.location.href = 'about:blank';
      window.close(); // 火狐默认状态非window.open的页面window.close是无效的
    } else {
      window.opener = null;
      window.open('', '_self', '');
      window.close();
    }
  }

  clickPrincipalUserId(id) {
    this.principalUserId = id;
  }

  clicktEntrustUsersId(id) {
    this.getEntrustUsersId = id;
  }

  @Watch('_flowConfig')
  flowConfigWatcher(val) {
    this.getReportList();
    this.flowId = val.id;
  }

  @Watch('_currentTask')
  currentTaskWatcher(val) {
    this.formDataId = val.dataInstanceId;
  }

  @Watch('_formConfigs')
  formConfigsWatcher() {
    if (this._flowConfig && this._flowConfig.id && this._flowConfig.customBpmModel.config) {
      this.formId = this._flowConfig.customBpmModel.config.formId;
    } else {
      this.formId = null;
    }
  }

  @Watch('_pageLoading')
  pageLoadingWatcher() {
    this.initData();
  }

  /**
   * 返回首页
   */
  goToHome() {
    this.$router.go(-1);

    // if (this.$route.query && this.$route.query.backFlg) {
    // } else {
    //   this.$router.push({ path: '/front2' });
    // }
  }

  /**
   * 构建流程日志
   */
  buildLogs(taskFlows, taskHistories) {
    let result: any = [];
    let taskHistoryMap: any = {};
    taskHistories.forEach(historyItem => {
      taskHistoryMap[historyItem.id] = historyItem;
    });
    taskFlows.forEach(nodeItem => {
      let nodeItemTmp = SystemUtil.cloneDeep(nodeItem);
      nodeItemTmp.taskHistories = [];
      if (nodeItem.taskHistories) {
        nodeItem.taskHistories.forEach(logItem => {
          nodeItemTmp.taskHistories.push(taskHistoryMap[logItem.id]);
        });
      }
      result.push(nodeItem);
    });
    return result;
  }
}
